import React, { useState } from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AnalyticProvider from './contexts/AnalyticContext';
import Layout from './components/Layout';

/* Misc Pages */
import Dashboard from './pages/Dashboard';

/* Public Pages */
import Registration from './pages/Registration';
import Login from './pages/Login';
import StorableItems from './pages/StorableItems';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Pricing from './pages/Pricing';
import Careers from './pages/Careers';

/* Customer Pages */
import MyItems from './pages/customer/MyItems';
import StoragePlanPrivate from './pages/customer/StoragePlanPrivate';
import SupportTicketForm from './pages/customer/SupportTicketForm';
import Settings from './pages/customer/AccountSettings';
import Appointments from './pages/customer/Appointments';
import AddItem from './pages/customer/AddItem';
import ItemInfo from './pages/customer/ItemInfo';
import Support from './pages/customer/Support';
import EditItem from './pages/customer/EditItem';
import Inbox from './pages/customer/Inbox';
import UserDashboard from './pages/admin/UserDashboard';
import Invoices from './pages/customer/Invoices';

/* Admin Pages */
import AppointmentList from './pages/admin/AppointmentList';
import UniversityEditor from './pages/admin/UniversityEditor';
import Universities from './pages/admin/UniversitySettings';
import AdminDashboard from './pages/admin/AdminDashboard';
import SystemMonitoring from './pages/admin/SystemMonitoring';
import SalesDashboard from './pages/admin/SalesDashboard';
import SalesDetail from './pages/admin/SalesDetail';
import UserDetail from './pages/admin/UserDetail';
import ContainerXrayPage from './pages/admin/ContainerXrayPage';
import UniversityContainersView from './pages/admin/UniversityContainersView';
import UniversityContainersSelection from './pages/admin/ContainersUniversityPicker';
import ContainerLocationEditor from './pages/admin/ContainerLocationEditor';
import ActivityFeed from './pages/admin/ActivityFeed';
import AppointmentEditor from './pages/admin/AppointmentEditor';
import NotificationDashboard from './pages/admin/NotificationDashboard';
import NotificationEditor from './pages/admin/NotificationEditor';
import CustomerTickets from './pages/admin/CustomerTickets';

/* Coordinator Pages */
import CoordinatorDashboard from './pages/coordinator/CoordinatorDashboard';

/* Other imports */
import { NotificationProvider } from './contexts/NotificationContext';
import { PageNotificationProvider } from './contexts/PageNotificationContext'; // Import the notification context
import { useAuth } from './contexts/AuthContext';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';

import './App.css';
import InvoiceDetailsPage from './pages/customer/InvoiceDetailsPage';
import InvoicePay from './pages/customer/InvoicePay';
import TicketDetailPage from './pages/admin/TicketDetailPage';
import ContainerDetails from './pages/admin/ContainerDetails';
import ContainerFlowGraph from './components/ContainerFlowGraph';
import SpecialMoveUniversitySelector from './pages/admin/SpecialMoveUniversitySelector';
import SpecialMoves from './pages/admin/SpecialMoves';
import Movers from './pages/customer/Movers';
import VerifyEmail from './pages/VerifyEmail';
import MoversPage from './pages/Movers';
import { MaintenanceProvider, useMaintenance } from './contexts/MaintenanceContext'; // Import here

import MaintenancePage from './pages/MaintenancePage';
import FAQPage from './pages/FAQ';
import Unsubscribe from './pages/Unsubscribe';
import MarketingCampaignCreation from './pages/admin/MarketingCampaignCreation';
import MarketingCampaignOverview from './pages/admin/MarketingCampaignOverview';
import MoversForm from './pages/MoversForm';
import CoordinatorForm from './pages/CoordinatorForm';
import ApplicationConfirm from './pages/ApplicationConfirm';

const theme = createTheme({
  palette: {
    primary: { main: '#134F75' },
    secondary: { main: '#007bff', alternate: '#072a40' },
    background: { default: '#f8f9fa' },
  },
  typography: { fontFamily: 'Arial, sans-serif' },
});

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  function DashboardRoute() {
    const { userClaims } = useAuth();
    const { maintenanceMode } = useMaintenance();

    // If maintenance mode is enabled, show a maintenance page instead.
    if (maintenanceMode && userClaims.role !== 'admin') {
      return <Layout
        theme={theme}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        breadcrumb={
          userClaims.role === 'admin'
            ? 'Admin Dashboard'
            : userClaims.role === 'coordinator'
              ? 'Coordinator Dashboard'
              : 'Customer Dashboard'
        }
      >
        <MaintenancePage></MaintenancePage>
      </Layout>;
    } else {
      return (
        <PrivateRoute>
          <Layout
            theme={theme}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            sidebarOpen={sidebarOpen}
            toggleSidebar={toggleSidebar}
            breadcrumb={
              userClaims.role === 'admin'
                ? 'Admin Dashboard'
                : userClaims.role === 'coordinator'
                  ? 'Coordinator Dashboard'
                  : 'Customer Dashboard'
            }
          >
            <Routes>
              {/* ADMIN ROUTES */}
              {userClaims.role === 'admin' ? (
                <>
                  <Route path="" element={<AdminDashboard />} />
                  <Route path="universities" element={<Universities />} />
                  <Route path="universities/:universityID" element={<UniversityEditor />} />
                  <Route path="system-monitoring" element={<SystemMonitoring />} />
                  <Route path="notifications" element={<NotificationDashboard />} />
                  <Route path="notifications/new" element={<NotificationEditor />} />
                  <Route path="notifications/edit/:notificationId" element={<NotificationEditor />} />
                  <Route path="notifications/campaign/new/:notificationId" element={<MarketingCampaignCreation />} />
                  <Route path="notifications/campaign/overview/:campaignId" element={<MarketingCampaignOverview />} />
                  <Route path="containers" element={<UniversityContainersSelection />} />
                  <Route path="containers/university/:universityID" element={<UniversityContainersView />} />
                  <Route path="containers/university/graph/:universityID" element={<ContainerFlowGraph />} />
                  <Route path="containers/university/:universityID/:containerLocationID" element={<ContainerLocationEditor />} />
                  <Route path="containers/university/:universityID/:containerLocationID/appointments" element={<AppointmentList />} />
                  <Route path="containers/university/:universityID/:containerLocationID/appointments/:appointmentID" element={<AppointmentEditor />} />
                  <Route path="containers/container/:containerID/xray" element={<ContainerXrayPage />} />
                  <Route path="containers/container/:containerID" element={<ContainerDetails />} />
                  <Route path="sales-dashboard" element={<SalesDashboard />} />
                  <Route path="sales-dashboard/:transactionId" element={<SalesDetail />} />
                  <Route path="users" element={<UserDashboard />} />
                  <Route path="users/:userId" element={<UserDetail />} />
                  <Route path="activity-feed" element={<ActivityFeed />} />
                  <Route path="container-viewer" element={<Dashboard />} />
                  <Route path="special-moves" element={<SpecialMoveUniversitySelector />} />
                  <Route path="special-moves/:universityID" element={<SpecialMoves />} />
                  <Route path="tickets" element={<CustomerTickets />} />
                  <Route path="tickets/:ticketID" element={<TicketDetailPage />} />
                </>
              ) : userClaims.role === 'coordinator' ? (
                /* COORDINATOR ROUTES */
                <>
                  <Route path="" element={<CoordinatorDashboard />} />
                </>
              ) : (
                /* CUSTOMER ROUTES */
                <>
                  <Route path="" element={<Dashboard />} />
                  <Route path="items" element={<MyItems />} />
                  <Route path="plan" element={<StoragePlanPrivate />} />
                  <Route path="add-item" element={<AddItem />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="appointments" element={<Appointments />} />
                  <Route path="support" element={<Support />} />
                  <Route path="movers" element={<Movers />} />
                  <Route path="support/create-ticket" element={<SupportTicketForm />} />
                  <Route path="inbox" element={<Inbox />} />
                  <Route path="payments" element={<Invoices />} />
                  <Route path="edit-item/:itemId" element={<EditItem />} />
                  <Route path="item-info/:itemId" element={<ItemInfo />} />
                  <Route path="payments/invoice/details/:invoiceId" element={<InvoiceDetailsPage />} />
                  <Route path="payments/invoice/pay/:invoiceId" element={<InvoicePay />} />
                </>
              )}
            </Routes>
          </Layout>
        </PrivateRoute>
      );

    }

  }

  return (
    <Router>
      <AuthProvider>
        <AnalyticProvider>
          <NotificationProvider>
            <MaintenanceProvider>
              <PageNotificationProvider>{/* Added PageNotificationProvider here */}
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <Routes>
                    {/* PUBLIC ROUTES */}
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/registration" element={<Registration />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/storable-items" element={<StorableItems />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/faq" element={<FAQPage />} />
                    <Route path="/jobs" element={<Careers />} />
                    <Route path="/verify-email" element={<VerifyEmail />} />
                    <Route path="/unsubscribe" element={<Unsubscribe/>} />
                    <Route path="/movers" element={<MoversPage />} />
                    <Route path="/movers-form" element={<MoversForm />} />
                    <Route path="/coordinator-form" element={<CoordinatorForm />} />
                    <Route path="/application-confirm" element={<ApplicationConfirm />} />

                    {/* PROTECTED DASHBOARD ROUTE */}
                    <Route path="/dashboard/*" element={<DashboardRoute />} />
                  </Routes>
                </ThemeProvider>
              </PageNotificationProvider>
            </MaintenanceProvider>
          </NotificationProvider>
        </AnalyticProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
