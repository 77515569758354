import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const ApplicationConfirm = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ minHeight: '100vh', width: '100%' }}>
            <NavBar />
            <Container maxWidth="sm" sx={{ my: 8, textAlign: 'center' }}>
                <Typography variant="h3" sx={{ fontFamily: 'Work Sans', fontWeight: 'bold', mb: 4 }}>
                    Thank You for Applying!
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                    We have received your application and will be in touch soon.
                </Typography>
                <Button variant="contained" color="primary" onClick={() => navigate('/')}>
                    Back to Home
                </Button>
            </Container>
            <Footer />
        </Box>
    );
};

export default ApplicationConfirm;
