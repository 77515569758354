// File: src/pages/MarketingCampaignCreation.jsx
import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    TablePagination,
    Stepper,
    Step,
    StepLabel,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Papa from 'papaparse';
import { db } from '../../firebase';
import { collection, addDoc, getDocs, doc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import NotificationPreviewEmail from '../../components/NotificationPreviewEmail';

const steps = ['Select/Create Email List', 'Preview Email', 'Confirm Send'];

const MarketingCampaignCreation = () => {
    // Get the notificationId from URL parameters.
    const { notificationId } = useParams();

    // Stepper state.
    const [activeStep, setActiveStep] = useState(0);

    // Step 1: Email list selection/creation state.
    const [emailListMode, setEmailListMode] = useState('create'); // 'create' or 'select'
    const [listName, setListName] = useState('');
    const [emailData, setEmailData] = useState([]); // Array of { firstName, lastName, email }
    const [fileName, setFileName] = useState('');
    const [selectedEmailList, setSelectedEmailList] = useState(null);
    const [existingEmailLists, setExistingEmailLists] = useState([]);
    const [loadingExistingLists, setLoadingExistingLists] = useState(false);
    const [message, setMessage] = useState('');
    const [saving, setSaving] = useState(false);

    // Pagination state for the table.
    const [page, setPage] = useState(0);
    const rowsPerPage = 20;

    // Step 2: Email preview state.
    const [emailSubject, setEmailSubject] = useState('Your Campaign Email Subject');
    const [emailBody, setEmailBody] = useState('<p>Your email content goes here...</p>');
    const [emailComponentsMap, setEmailComponentsMap] = useState({});

    // Step 3: Confirmation state.
    const [confirmationText, setConfirmationText] = useState('');

    // ------------------------------
    // Fetch Notification Data (if provided)
    // ------------------------------
    useEffect(() => {
        if (notificationId) {
            const fetchNotificationData = async () => {
                try {
                    const docRef = doc(db, 'notifications', notificationId);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        setEmailSubject(data.emailSubject || '');
                        setEmailBody(data.emailBody || '');
                        if (data.detailHasEmail) {
                            const emailComponentsSnapshot = await getDocs(collection(db, 'emailComponents'));
                            const mapping = {};
                            emailComponentsSnapshot.forEach((docSnap) => {
                                const compData = docSnap.data();
                                mapping[compData.name.trim()] = compData.body;
                            });
                            setEmailComponentsMap(mapping);
                        }
                    }
                } catch (error) {
                    console.error('Error fetching notification data:', error);
                }
            };
            fetchNotificationData();
        }
    }, [notificationId]);

    // ------------------------------
    // Fetch Existing Email Lists when in "select" mode.
    // ------------------------------
    useEffect(() => {
        if (emailListMode === 'select') {
            const fetchLists = async () => {
                setLoadingExistingLists(true);
                try {
                    const querySnapshot = await getDocs(collection(db, 'marketingEmailLists'));
                    const lists = [];
                    querySnapshot.forEach((doc) => {
                        lists.push({ id: doc.id, ...doc.data() });
                    });
                    setExistingEmailLists(lists);
                } catch (error) {
                    console.error('Error fetching email lists:', error);
                } finally {
                    setLoadingExistingLists(false);
                }
            };
            fetchLists();
        }
    }, [emailListMode]);

    // ------------------------------
    // Handle CSV File Upload for new email list.
    // ------------------------------
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        if (file.type !== 'text/csv') {
            setMessage('Only CSV files are accepted.');
            return;
        }
        setFileName(file.name);

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                const requiredColumns = ['First Name', 'Last Name', 'Email'];
                const firstRow = results.data[0];
                const hasColumns = requiredColumns.every((col) => firstRow.hasOwnProperty(col));
                if (!hasColumns) {
                    setMessage('Uploaded file must have columns: First Name, Last Name, Email');
                    return;
                }
                const data = results.data.map((row) => ({
                    firstName: row['First Name'],
                    lastName: row['Last Name'],
                    email: row['Email'],
                }));
                setEmailData(data);
                setMessage(`Parsed ${data.length} emails successfully.`);
            },
            error: function (err) {
                setMessage(`Error parsing file: ${err.message}`);
            },
        });
    };

    // ------------------------------
    // Save new email list to Firestore.
    // ------------------------------
    const handleSaveEmailList = async () => {
        if (!listName || emailData.length === 0) {
            setMessage('Please provide a list name and upload a valid CSV file.');
            return;
        }
        setSaving(true);
        const emailNameMap = {};
        emailData.forEach((entry) => {
            emailNameMap[entry.email] = `${entry.firstName} ${entry.lastName}`;
        });
        try {
            const docRef = await addDoc(collection(db, 'marketingEmailLists'), {
                listName,
                emailNameMap,
                notificationId: notificationId || null,
                createdAt: new Date().toISOString(),
            });
            setMessage(`Email list saved successfully with id: ${docRef.id}`);
            setSelectedEmailList({ id: docRef.id, listName, emailNameMap, count: emailData.length });
        } catch (error) {
            console.error('Error saving email list:', error);
            setMessage('Error saving email list.');
        } finally {
            setSaving(false);
        }
    };

    // ------------------------------
    // Stepper Navigation Handlers.
    // ------------------------------
    const handleNext = () => {
        if (activeStep === 0) {
            if (emailListMode === 'create') {
                if (!selectedEmailList && (listName === '' || emailData.length === 0)) {
                    setMessage('Please create an email list before proceeding.');
                    return;
                }
            } else if (emailListMode === 'select') {
                if (!selectedEmailList) {
                    setMessage('Please select an email list before proceeding.');
                    return;
                }
            }
        }
        setActiveStep((prev) => prev + 1);
        setMessage('');
    };

    const handleBack = () => {
        setActiveStep((prev) => prev - 1);
        setMessage('');
    };

    // ------------------------------
    // Step 3: Confirmation handler (calls batch function).
    // ------------------------------
    const handleConfirmSend = async () => {
        if (confirmationText.toLowerCase() !== 'yes') {
            setMessage('Please type "yes" to confirm.');
            return;
        }
        try {
            const functions = getFunctions();
            const sendBatch = httpsCallable(functions, 'sendMarketingNotificationBatch');
            const result = await sendBatch({ emailListId: selectedEmailList.id, notificationId });
            if (result.data && result.data.success) {
                setMessage(result.data.message);
            } else {
                setMessage('Failed to send campaign emails.');
            }
        } catch (error) {
            console.error('Error sending campaign emails:', error);
            setMessage('Error sending campaign emails.');
        }
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
                Marketing Campaign Creation
            </Typography>
            {notificationId && (
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    Creating campaign for Notification ID: {notificationId}
                </Typography>
            )}
            <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            {activeStep === 0 && (
                <Box>
                    <FormControl component="fieldset" sx={{ mb: 2 }}>
                        <FormLabel component="legend">Email List Option</FormLabel>
                        <RadioGroup
                            row
                            value={emailListMode}
                            onChange={(e) => {
                                setEmailListMode(e.target.value);
                                setSelectedEmailList(null);
                                setMessage('');
                            }}
                        >
                            <FormControlLabel value="create" control={<Radio />} label="Create New Email List" />
                            <FormControlLabel value="select" control={<Radio />} label="Select Existing Email List" />
                        </RadioGroup>
                    </FormControl>

                    {emailListMode === 'create' ? (
                        <Box>
                            <TextField
                                label="Email List Name"
                                value={listName}
                                onChange={(e) => setListName(e.target.value)}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <Button variant="contained" component="label">
                                Upload CSV File (Only CSV accepted)
                                <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
                            </Button>
                            {fileName && (
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    Uploaded File: {fileName}
                                </Typography>
                            )}
                            {emailData.length > 0 && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="subtitle1">{emailData.length} Emails Added</Typography>
                                    <Paper sx={{ maxHeight: 300, overflow: 'auto', mt: 1 }}>
                                        <Table stickyHeader size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>First Name</TableCell>
                                                    <TableCell>Last Name</TableCell>
                                                    <TableCell>Email</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {emailData
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{row.firstName}</TableCell>
                                                            <TableCell>{row.lastName}</TableCell>
                                                            <TableCell>{row.email}</TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            count={emailData.length}
                                            page={page}
                                            onPageChange={(e, newPage) => setPage(newPage)}
                                            rowsPerPage={rowsPerPage}
                                            rowsPerPageOptions={[rowsPerPage]}
                                        />
                                    </Paper>
                                    <Button variant="contained" onClick={handleSaveEmailList} sx={{ mt: 2 }} disabled={saving}>
                                        {saving ? 'Saving...' : 'Save Email List'}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box>
                            {loadingExistingLists ? (
                                <Typography>Loading existing email lists...</Typography>
                            ) : existingEmailLists.length === 0 ? (
                                <Typography>No existing email lists found.</Typography>
                            ) : (
                                <Box>
                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <FormLabel>Select an Email List</FormLabel>
                                        <select
                                            value={selectedEmailList ? selectedEmailList.id : ''}
                                            onChange={(e) => {
                                                const list = existingEmailLists.find((l) => l.id === e.target.value);
                                                setSelectedEmailList(list);
                                            }}
                                        >
                                            <option value="">-- Select --</option>
                                            {existingEmailLists.map((list) => (
                                                <option key={list.id} value={list.id}>
                                                    {list.listName} ({Object.keys(list.emailNameMap).length} emails)
                                                </option>
                                            ))}
                                        </select>
                                    </FormControl>
                                </Box>
                            )}
                        </Box>
                    )}

                    {message && (
                        <Typography variant="body1" color="primary" sx={{ mt: 2 }}>
                            {message}
                        </Typography>
                    )}

                    <Box sx={{ mt: 2 }}>
                        <Button variant="contained" onClick={handleNext}>
                            Next
                        </Button>
                    </Box>
                </Box>
            )}

            {activeStep === 1 && (
                <Box>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Preview Email
                    </Typography>
                    {/* Render the preview component without editable fields */}
                    <NotificationPreviewEmail
                        subject={emailSubject}
                        body={emailBody}
                        components={emailComponentsMap}
                    />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="outlined" onClick={handleBack}>
                            Back
                        </Button>
                        <Button variant="contained" onClick={handleNext}>
                            Next
                        </Button>
                    </Box>
                </Box>
            )}

            {activeStep === 2 && (
                <Box>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Confirm Send
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        You are sending an email to {selectedEmailList?.count || 0} emails. Are you sure you would like to proceed?
                        Type "yes" to confirm.
                    </Typography>
                    <TextField
                        label='Type "yes" to confirm'
                        value={confirmationText}
                        onChange={(e) => setConfirmationText(e.target.value)}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    {message && (
                        <Typography variant="body1" color="primary" sx={{ mb: 2 }}>
                            {message}
                        </Typography>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="outlined" onClick={handleBack}>
                            Back
                        </Button>
                        <Button variant="contained" onClick={handleConfirmSend}>
                            Send Email Campaign
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default MarketingCampaignCreation;
