// File: src/pages/MarketingCampaignOverview.jsx
import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Button,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';

const MarketingCampaignOverview = () => {
    const { campaignId } = useParams();
    const navigate = useNavigate();
    const [campaign, setCampaign] = useState(null);
    const [mailDocs, setMailDocs] = useState([]); // State for fetched mail documents
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    // Helper function to chunk an array (Firestore in queries are limited to 10 elements)
    const chunkArray = (arr, size) => {
        const results = [];
        for (let i = 0; i < arr.length; i += size) {
            results.push(arr.slice(i, i + size));
        }
        return results;
    };

    // Fetch the campaign document and then batch fetch the associated mail documents from the "mail" collection.
    useEffect(() => {
        const fetchCampaignAndMails = async () => {
            try {
                const campaignDocRef = doc(db, 'marketingCampaigns', campaignId);
                const campaignDoc = await getDoc(campaignDocRef);
                if (campaignDoc.exists()) {
                    const campaignData = campaignDoc.data();
                    setCampaign(campaignData);

                    // Batch fetch mail documents if sentMap exists
                    if (campaignData.sentMap) {
                        const mailIds = Object.values(campaignData.sentMap);
                        const mailRef = collection(db, 'mail'); // Updated collection name
                        let fetchedMailDocs = [];

                        // Chunk the mailIds array to respect Firestore's in-query limitation
                        const chunks = chunkArray(mailIds, 10);
                        for (const chunk of chunks) {
                            const q = query(mailRef, where('__name__', 'in', chunk));
                            const snapshot = await getDocs(q);
                            snapshot.forEach(mailDoc => {
                                fetchedMailDocs.push({ id: mailDoc.id, ...mailDoc.data() });
                            });
                        }
                        setMailDocs(fetchedMailDocs);
                    }
                } else {
                    setError('Campaign not found.');
                }
            } catch (err) {
                console.error('Error fetching campaign or mail documents:', err);
                setError('Failed to fetch campaign.');
            } finally {
                setLoading(false);
            }
        };

        if (campaignId) {
            fetchCampaignAndMails();
        } else {
            setError('No campaign ID provided.');
            setLoading(false);
        }
    }, [campaignId]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
                <Button variant="contained" onClick={() => navigate('/campaigns')}>
                    Back to Campaigns
                </Button>
            </Box>
        );
    }

    const { notificationId, emailListId, totalEmails, processedEmails, sentMap, createdAt } = campaign;

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
                Campaign Overview
            </Typography>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="subtitle1"><strong>Campaign ID:</strong> {campaignId}</Typography>
                <Typography variant="subtitle1"><strong>Notification ID:</strong> {notificationId}</Typography>
                <Typography variant="subtitle1"><strong>Email List ID:</strong> {emailListId}</Typography>
                <Typography variant="subtitle1">
                    <strong>Total Emails in List:</strong> {totalEmails}
                </Typography>
                <Typography variant="subtitle1">
                    <strong>Processed Emails:</strong> {processedEmails}
                </Typography>
                {createdAt && (
                    <Typography variant="subtitle1">
                        <strong>Created At:</strong> {new Date(createdAt.seconds * 1000).toLocaleString()}
                    </Typography>
                )}
            </Paper>

            <Typography variant="h6" gutterBottom>
                Email Send Details
            </Typography>
            <Paper sx={{ width: '100%', overflowX: 'auto', p: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>Mail Document ID</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sentMap &&
                            Object.entries(sentMap).map(([email, mailDocId]) => {
                                const mailDoc = mailDocs.find(doc => doc.id === mailDocId);

                                let status = 'Loading...';
                                if (mailDoc) {
                                    const { delivery } = mailDoc;
                                    if (delivery?.error) {
                                        status = 'Error';
                                    } else if (delivery?.info?.rejected?.length > 0) {
                                        status = 'Rejected';
                                    } else if (delivery?.info?.accepted?.length > 0) {
                                        status = 'Sent';
                                    } else {
                                        status = 'Pending';
                                    }
                                }

                                return (
                                    <TableRow key={email}>
                                        <TableCell>{email}</TableCell>
                                        <TableCell>{mailDocId}</TableCell>
                                        <TableCell>{status}</TableCell>
                                    </TableRow>
                                );
                            })
                        }

                    </TableBody>
                </Table>
            </Paper>

            <Box sx={{ mt: 3 }}>
                <Button variant="contained" onClick={() => navigate('/campaigns')}>
                    Back to Campaigns
                </Button>
            </Box>
        </Box>
    );
};

export default MarketingCampaignOverview;
