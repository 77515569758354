import React, { useState } from 'react';
import { Box, Container, Typography, CircularProgress, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import EmailIcon from '@mui/icons-material/Email';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Helper hook to parse query parameters.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

// Call the unsubscribeEmail callable Cloud Function.
async function unsubscribeEmail({ email, token }) {
    const functions = getFunctions();
    const unsubscribeEmailCallable = httpsCallable(functions, 'unsubscribeEmail');
    const result = await unsubscribeEmailCallable({ email, token });
    return result.data; // Expected to return { success, message }
}

const Unsubscribe = () => {
    const query = useQuery();
    const email = query.get('email');
    const token = query.get('token');
    const [status, setStatus] = useState('idle'); // 'idle', 'unsubscribing', 'success', 'error'
    const [message, setMessage] = useState('');

    const performUnsubscribe = async () => {
        if (!email || !token) {
            setStatus('error');
            setMessage('Missing email address or token.');
            return;
        }
        setStatus('unsubscribing');
        try {
            const result = await unsubscribeEmail({ email, token });
            if (result.success) {
                setStatus('success');
                setMessage('Your email has been unsubscribed successfully!');
            } else {
                setStatus('error');
                setMessage(result.message || 'Unsubscription failed. Please try again.');
            }
        } catch (error) {
            console.error('Unsubscribe error:', error);
            setStatus('error');
            setMessage('An error occurred during unsubscription. Please try again.');
        }
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <NavBar />
            <Container
                sx={{
                    width: "100%",
                    mt: 8,
                    mb: 8,
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Box
                    sx={{
                        textAlign: 'center',
                        mt: 4,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        maxWidth: 400
                    }}
                >
                    <EmailIcon sx={{ fontSize: 80, color: 'primary.main', mb: 2 }} />
                    {status === 'idle' && (
                        <>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Click the button below to unsubscribe your email.
                            </Typography>
                            <Button variant="contained" color="primary" onClick={performUnsubscribe}>
                                Unsubscribe Email
                            </Button>
                        </>
                    )}
                    {status === 'unsubscribing' && (
                        <Box>
                            <CircularProgress />
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                Processing your unsubscription...
                            </Typography>
                        </Box>
                    )}
                    {status === 'success' && (
                        <>
                            <Typography variant="h4" gutterBottom>
                                Unsubscribed!
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {message}
                            </Typography>
                            <Button variant="contained" color="primary" href="/" sx={{ mt: 2 }}>
                                Back to Home
                            </Button>
                        </>
                    )}
                    {status === 'error' && (
                        <>
                            <Typography variant="h4" gutterBottom>
                                Unsubscription Failed
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {message}
                            </Typography>
                            <Button variant="contained" color="primary" onClick={performUnsubscribe} sx={{ mt: 2 }}>
                                Try Again
                            </Button>
                        </>
                    )}
                </Box>
            </Container>
            <Footer />
        </Box>
    );
};

export default Unsubscribe;
