import React from 'react';
import { Box, Typography, Container, Grid, Button } from '@mui/material';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';

const Movers = () => {

    const navigate = useNavigate();
    const processSteps = [
        {
            title: 'Schedule Your Move',
            imageUrl: '/images/schedule.jpg', // update with your actual image path
            description: 'Choose a date and time that suits you. Our movers will be there right on schedule to kick off your move.'
        },
        {
            title: 'Packing and Loading',
            imageUrl: '/images/packing.jpg', // update with your actual image path
            description: 'Our expert team carefully packs and loads your items to ensure they’re safe and secure for the journey.'
        },
        {
            title: 'Transportation',
            imageUrl: '/images/transport.jpg', // update with your actual image path
            description: 'Your belongings are transported in our state-of-the-art vehicles, ensuring a smooth ride to your destination.'
        },
        {
            title: 'Unloading and Setup',
            imageUrl: '/images/unloading.jpg', // update with your actual image path
            description: 'Once we arrive, our team unloads and helps set up your items just the way you want them.'
        }
    ];

    const scrollToProcess = () => {
        const element = document.getElementById("process");
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Box sx={{ mb: 0, height: "100vh", width: "100%" }}>
            <NavBar />

            {/* Updated Hero Section */}
            <Container
                maxWidth={false}
                disableGutters
                sx={{ 
                    backgroundColor: 'primary.main',
                     color: 'white', 
                     height: '90vh', 
                     overflow: 'hidden', 
                     position: 'relative', 
                     display: 'flex',
                     flexDirection: 'column' }}
            >
                {/* Background Pattern Overlay */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: "url('/images/BackgroundPatternBoxes-01.webp')",
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        opacity: 0.5,
                        zIndex: 0,
                    }}
                />

                <Container maxWidth='none' sx={{ position: 'relative', zIndex: 1,  width: "100vw", mx: 0, px: 5 }}>
                    <Grid container maxWidth='none' spacing={4} alignItems="center" sx={{px: 5}}>
                        {/* Left Section */}
                        <Grid item xs={12} md={4}>
                            <Typography variant="h2" sx={{ fontFamily: 'Work Sans', fontWeight: 'bold', mb: 2 }}>
                                Mover Service
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 4, fontFamily: 'Work Sans' }}>
                                Our student movers are an <strong>optional service</strong>, available with every storage plan for <strong>$35 per mover</strong>, per move.
                            </Typography>

                            <Button onClick={() => navigate('/dashboard/movers')} variant="contained" color="secondary" sx={{ fontSize: '16px', padding: '16px 32px', width: '100%', fontWeight: 'bold', mb: 2 }}>Request Movers</Button>
                            <Button variant="outlined" color="inherit" onClick={scrollToProcess} sx={{ fontSize: '16px', padding: '16px 32px', width: '100%', fontWeight: 'bold', mb: 2 }}>
                                How it works
                            </Button>
                        </Grid>

                        {/* Center Image Section */}
                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    height: { xs: '300px', md: '100vh' },
                                    backgroundImage: "url('/images/movers_box_tall.jpg')",
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    borderRight: '5px solid white',
                                    borderLeft: '5px solid white',
                                    borderTop: '5px solid white',
                                }}
                            />
                        </Grid>

                        {/* Right Section */}
                        <Grid item xs={12} md={4}>
                            <Typography variant="h2" sx={{ fontFamily: 'Work Sans', fontWeight: 'bold', mb: 2, textAlign: 'right' }}>
                                Join Us
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 4, fontFamily: 'Work Sans', textAlign: 'right' }}>
                                Interested in making a difference? Join our team of student movers and start earning while you learn.
                            </Typography>
                            <Button onClick={() => navigate('/dashboard/movers')} variant="contained" color="secondary" sx={{ fontSize: '16px', padding: '16px 32px', width: '100%', fontWeight: 'bold', mb: 2 }}>Apply Now</Button>
                            <Button variant="outlined" color="inherit" onClick={scrollToProcess} sx={{ fontSize: '16px', padding: '16px 32px', width: '100%', fontWeight: 'bold', mb: 2 }}>
                                Learn More
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Container>

            {/* Mover Service Info Section */}
            <Container
                id="process"
                maxWidth="none"
                sx={{
                    width: '100vw',
                    position: 'relative',
                    minHeight: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    mx: 0
                }}
            >
                <Grid container alignItems="center" sx={{ p: 0 }}>
                    {/* Left Text Section */}
                    <Grid item xs={12} md={6} sx={{p: 0}}>
                        <Typography variant="h3" sx={{ fontFamily: 'Work Sans', fontWeight: 'bold', mb: 2 }}>
                            How Our Mover Service Works
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
                            For move out, students will be asked to bring their items to a designated drop off location.
                            For move in, they will be requested to go to a pickup location to collect their items.
                            You can opt for student movers at <strong>$35 per mover, per move</strong>, or you may choose to move your items yourself.
                        </Typography>
                    </Grid>

                    {/* Right Image Section */}
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                width: '100%',
                                height: '100vh',
                                backgroundImage: "url('/images/movers_box_tall.jpg')", // update with your actual image path
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>


            <Footer />
        </Box>
    );
};

export default Movers;
