import React, { useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Grid,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ReCAPTCHA from 'react-google-recaptcha';

// Import Firebase functions from your Firebase client setup
import { getFunctions, httpsCallable } from 'firebase/functions';

const MoversForm = () => {
    const navigate = useNavigate();
    const availableDays = ['May 5th', 'May 6th', 'May 7th', 'May 8th'];
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        streetAddress: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        studentId: '',
        college: '',
        emergencyContactName: '',
        emergencyContactPhone: '',
        availability: [],
        experience: '',
        additionalInfo: '',
        terms: false
    });

    const functions = getFunctions(); // Assumes Firebase has been initialized in your project
    const submitMoverApplication = httpsCallable(functions, 'submitMoverApplication');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox' && name === 'terms') {
            setFormData({ ...formData, [name]: checked });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleAvailabilityChange = (e) => {
        const { name, checked } = e.target;
        let newAvailability = [...formData.availability];
        if (checked) {
            newAvailability.push(name);
        } else {
            newAvailability = newAvailability.filter(day => day !== name);
        }
        setFormData({ ...formData, availability: newAvailability });
    };

    const handleRecaptcha = (token) => {
        setRecaptchaToken(token);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.terms) {
            alert("Please agree to the terms and conditions.");
            return;
        }
        if (!recaptchaToken) {
            alert("Please complete the reCAPTCHA.");
            return;
        }

        const payload = {
            ...formData,
            recaptchaToken: recaptchaToken
        };

        setLoading(true);
        try {
            const result = await submitMoverApplication(payload);
            // Optionally, you can show a message before redirecting
            console.log(result.data.message);
            // Reset the form if needed
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                streetAddress: '',
                addressLine2: '',
                city: '',
                state: '',
                zipCode: '',
                studentId: '',
                college: '',
                emergencyContactName: '',
                emergencyContactPhone: '',
                availability: [],
                experience: '',
                additionalInfo: '',
                terms: false
            });
            setRecaptchaToken(null);
            // Navigate to the confirmation page
            navigate('/application-confirm');
        } catch (error) {
            console.error("Submission error:", error);
            alert("An error occurred while submitting your application.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ minHeight: '100vh', width: '100%' }}>
            <NavBar />
            <Container maxWidth="md" sx={{ my: 4 }}>
                <Typography
                    variant="h3"
                    component="h1"
                    sx={{ fontFamily: 'Work Sans', fontWeight: 'bold', mb: 4, textAlign: 'center' }}
                >
                    Apply to be a Mover
                </Typography>

                {/* Detailed Job Description */}
                <Box sx={{ mb: 4, p: 3, border: '1px solid #ddd', borderRadius: 2, backgroundColor: '#f9f9f9' }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Detailed Job Description
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        <strong>Job Type:</strong> Independent Contractor
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        <strong>Pay Rate:</strong> $25 per move. Movers can complete up to 4 moves in one hour, earning up to $100 per hour.
                        Please note that movers may not sign up for more than 4 moves per 1-hour segment, per day.
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        <strong>Shift Timing:</strong> All moves are scheduled between <strong>10:00 AM and 3:00 PM</strong> on the selected days.
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        <strong>Dashboard Access:</strong> Upon approval, movers will gain access to a dashboard where they can pick up available shifts.
                        All movers are required to pick up at least 4 shifts.
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        <strong>Job Requirements:</strong> Applicants must be able to safely lift up to 50 pounds. A free mover t-shirt is provided with every move.
                        Comprehensive safety training will be provided before your first shift.
                    </Typography>
                    <Typography variant="body1">
                        <strong>Responsibilities:</strong> As a mover, you will assist with packing, loading, transportation, and unloading of items.
                        Professionalism, punctuality, and excellent customer service are essential.
                    </Typography>
                </Box>

                {/* Application Form */}
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {/* Personal Contact Information */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                required
                                label="First Name"
                                name="firstName"
                                variant="outlined"
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                required
                                label="Last Name"
                                name="lastName"
                                variant="outlined"
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                label="Email"
                                name="email"
                                type="email"
                                variant="outlined"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                label="Phone Number"
                                name="phone"
                                type="tel"
                                variant="outlined"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                        </Grid>

                        {/* Address Information */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                label="Street Address"
                                name="streetAddress"
                                variant="outlined"
                                value={formData.streetAddress}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Address Line 2 (Optional)"
                                name="addressLine2"
                                variant="outlined"
                                value={formData.addressLine2}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                required
                                label="City"
                                name="city"
                                variant="outlined"
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                fullWidth
                                required
                                label="State"
                                name="state"
                                variant="outlined"
                                value={formData.state}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                fullWidth
                                required
                                label="Zip Code"
                                name="zipCode"
                                variant="outlined"
                                value={formData.zipCode}
                                onChange={handleChange}
                            />
                        </Grid>

                        {/* Student and College Information */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                required
                                label="Student ID"
                                name="studentId"
                                variant="outlined"
                                value={formData.studentId}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                required
                                label="College/University"
                                name="college"
                                variant="outlined"
                                value={formData.college}
                                onChange={handleChange}
                            />
                        </Grid>

                        {/* Emergency Contact Information */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                required
                                label="Emergency Contact Name"
                                name="emergencyContactName"
                                variant="outlined"
                                value={formData.emergencyContactName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                required
                                label="Emergency Contact Phone"
                                name="emergencyContactPhone"
                                type="tel"
                                variant="outlined"
                                value={formData.emergencyContactPhone}
                                onChange={handleChange}
                            />
                        </Grid>

                        {/* Shift Availability */}
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                                Available Shift Days (Select one or more)
                            </Typography>
                            <FormGroup row>
                                {availableDays.map(day => (
                                    <FormControlLabel
                                        key={day}
                                        control={
                                            <Checkbox
                                                checked={formData.availability.includes(day)}
                                                onChange={handleAvailabilityChange}
                                                name={day}
                                            />
                                        }
                                        label={day}
                                    />
                                ))}
                            </FormGroup>
                        </Grid>

                        {/* Previous Experience */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={3}
                                label="Previous Moving or Related Experience (Optional)"
                                name="experience"
                                variant="outlined"
                                value={formData.experience}
                                onChange={handleChange}
                            />
                        </Grid>

                        {/* Additional Comments */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={3}
                                label="Additional Information or Comments (Optional)"
                                name="additionalInfo"
                                variant="outlined"
                                value={formData.additionalInfo}
                                onChange={handleChange}
                            />
                        </Grid>

                        {/* Terms and Conditions */}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="terms"
                                        checked={formData.terms}
                                        onChange={handleChange}
                                    />
                                }
                                label="I confirm that I can safely lift up to 50 pounds, understand that I may not sign up for more than 4 moves per 1-hour segment per day, and that I will be available for shifts from 10:00 AM to 3:00 PM on the selected days. "
                            />
                        </Grid>

                        {/* Google reCAPTCHA */}
                        <Grid item xs={12}>
                            <ReCAPTCHA
                                sitekey="6LerhwsrAAAAAGA1L0lMZEcQE8LKB0fivSul8HsJ"
                                onChange={handleRecaptcha}
                            />
                        </Grid>

                        {/* Submit Button */}
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{ py: 1.5, fontSize: '16px' }}
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit Application'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>

                {/* Go Back Button */}
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Button variant="outlined" onClick={() => navigate(-1)}>
                        Go Back
                    </Button>
                </Box>
            </Container>
            <Footer />
        </Box>
    );
};

export default MoversForm;
